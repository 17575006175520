import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import {
  Redirect,
  Route,
  BrowserRouter as Router,
  Switch,
} from "react-router-dom";
import "./App.css";
import { loginSuccess, logoutUser } from "./actions/userAction";
import LeftPaneComponent from "./components/LeftPaneComponent";
import LoginComponent from "./components/LoginComponent";
import AnalysisComponent from "./components/analysis/AnalysisComponent";
import CreateExpenseComponent from "./components/expenses/CreateExpenseComponent";
import EditExpenseComponent from "./components/expenses/EditExpenseComponent";
import MiscExpensesComponent from "./components/expenses/MiscExpensesComponent";
import GroupsComponent from "./components/groups/GroupsComponent";
import ViewGroupComponent from "./components/groups/ViewGroupComponent";
import HomeComponent from "./components/home/HomeComponent";
import LaunchComponent from "./components/launch/LaunchComponent";
import LoansComponent from "./components/loans/LoansComponent";
import LoansPaymentComponent from "./components/loans/loans-view/LoansPaymentComponent";
import NotificationsComponent from "./components/notifications/NotificationsComponent";
import { RegisterComponent } from "./components/register/RegisterComponent2";
import SavingsComponent from "./components/savings/SavingsComponent";
import SearchComponent from "./components/search/SearchComponent";
import SettingsComponent from "./components/settings/SettingsComponent";
import AccountsComponent from "./components/settings/accounts/AccountsComponent";
import IncomeSettingsComponent from "./components/settings/preferences/IncomeSettingsComponent";
import SecuritySettingsComponent from "./components/settings/security/SecuritySettingsComponent";
import SubscriptionsComponent from "./components/settings/subscriptions/SubscriptionsComponent";
import ResourceNotFoundComponent from "./components/shared/ResourceNotFoundComponent";
import PriyaTeamComponent from "./components/teams/priya.team.component";
import TripsComponent from "./components/trips/TripsComponent";
import * as AppConfigHelper from "./helpers/AppConfigHelper";
import { logout } from "./services/LoginService";
import { postSession } from "./services/UserService";
import store from "./store";
import "./styles/ButtonStyle.css";
import "./styles/ListStyle.css";
import "./styles/ModalStyle.css";
import "./styles/SelectStyle.css";
import "./styles/Spinner.css";
import "./styles/TableStyle.css";
import "./styles/TileStyle.css";
import MobileHeaderComponent from "./components/MobileHeaderComponent";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.isMobile = false;
  }

  async componentDidMount() {
    const userAgent = window.navigator.userAgent;
    // This is the only two option to check if mobile browser.
    this.isMobile =
      userAgent &&
      (userAgent.includes("Android") || userAgent.includes("iPhone"));
    // console.debug("User agent", userAgent, this.isMobile);

    try {
      const queryparams = window.location.search;
      const sanitizedParams = queryparams.replace("?", "").split("&");
      const tokenSSID = sanitizedParams.find((param) =>
        param.includes("exhut_token_ssid"),
      );
      let bearerToken;
      if (tokenSSID) {
        bearerToken = tokenSSID.split("=")[1];
      }
      const result = await postSession(bearerToken);
      if (result) {
        if (bearerToken) {
          AppConfigHelper.setSignature(bearerToken);
        }
        store.dispatch(loginSuccess(result));
      }
    } catch (err) {
      console.error(err);
    }
    this.setState({ isProgress: false });
  }

  async _doLogout(msg = "Session timed out.") {
    try {
      store.dispatch(logoutUser(msg));
      // keep this commented until EXHUT-1001 is resolved.
      await logout();
    } catch (err) {
      // just log the error if server calls failed.
      // eslint-disable-next-line no-console
      console.error(err);
    }
  }

  renderPremiumRoutes() {
    if (this.props.user.entitlement >= 3) {
      return (
        <>
          <Route
            exact
            path="/loans/:id"
            render={(props) => (
              <LoansPaymentComponent
                {...props}
                user={this.props.user}
                doLogout={this._doLogout}
              />
            )}
          />
          <Route exact path="/loans">
            <LoansComponent user={this.props.user} doLogout={this._doLogout} />
          </Route>
          <Route exact path="/notifications">
            <NotificationsComponent
              user={this.props.user}
              doLogout={this._doLogout}
            />
          </Route>
        </>
      );
    } else {
      return (
        <Route path="/loans">
          <ResourceNotFoundComponent
            user={this.props.user}
            doLogout={this._doLogout}
          />
        </Route>
      );
    }
  }

  render() {
    const urlParams = new URLSearchParams(window.location.search);
    return (
      <Router>
        <div style={{ display: "flex" }} id="pageContent">
          {!this.isMobile && (
            <div className="splitleft">
              <LeftPaneComponent
                user={this.props.user}
                onClickLogout={() => this._doLogout("")}
                authenticated={this.props.user !== undefined}
              />
            </div>
          )}

          <div
            className="splitright"
            style={{ width: this.isMobile ? "100%" : "80%" }}
          >
            <Switch>
              <Route path="/register">
                {this.props.user && <Redirect to="/home" />}
                {!this.props.user && (
                  <RegisterComponent isMobile={this.isMobile} />
                )}
              </Route>

              <Route path="/teams/priya">
                <PriyaTeamComponent isMobile={this.isMobile} />
              </Route>
              <Route exact path="/fnxverify">
                <RegisterComponent
                  isMobile={this.isMobile}
                  candidateId={urlParams.get("cid")}
                  regkey={urlParams.get("regkey")}
                  email={urlParams.get("email")}
                />
              </Route>
              {!this.props.user &&
                !["/", "/login", "/register", "/about", "/welcome"].some(
                  (e) => e === window.location.pathname,
                ) && (
                  <LoginComponent
                    errorMsg={this.props.logoutMsg}
                    isMobile={this.isMobile}
                  />
                )}
              <Route exact path="/login">
                {this.props.user && <Redirect to="/home" />}
                {!this.props.user && (
                  <LoginComponent isMobile={this.isMobile} />
                )}
              </Route>
              {this.props.user && (
                <Switch>
                  <Route path="/home">
                    <HomeComponent doLogout={this._doLogout} />
                  </Route>
                  <Route path="/addExpense">
                    <CreateExpenseComponent
                      user={this.props.user}
                      doLogout={this._doLogout}
                    />
                  </Route>
                  <Route path="/editExpense">
                    {this.props.editExpenseId && (
                      <EditExpenseComponent
                        user={this.props.user}
                        doLogout={this._doLogout}
                      />
                    )}
                    {!this.props.editExpenseId && (
                      <HomeComponent doLogout={this._doLogout} />
                    )}
                  </Route>
                  <Route path="/miscExpenses">
                    <MiscExpensesComponent doLogout={this._doLogout} />
                  </Route>
                  <Route path="/savings">
                    <SavingsComponent
                      user={this.props.user}
                      doLogout={this._doLogout}
                    />
                  </Route>

                  <Route path="/trips">
                    <TripsComponent
                      user={this.props.user}
                      doLogout={this._doLogout}
                    />
                  </Route>
                  <Route path="/analysis">
                    <AnalysisComponent
                      user={this.props.user}
                      doLogout={this._doLogout}
                    />
                  </Route>

                  <Route path="/search">
                    <SearchComponent
                      user={this.props.user}
                      doLogout={this._doLogout}
                    />
                  </Route>
                  <Route path="/settings/income">
                    <IncomeSettingsComponent
                      user={this.props.user}
                      doLogout={this._doLogout}
                    />
                  </Route>

                  <Route path="/settings/accounts">
                    <AccountsComponent
                      user={this.props.user}
                      doLogout={this._doLogout}
                    />
                  </Route>
                  <Route path="/settings/security">
                    <SecuritySettingsComponent
                      user={this.props.user}
                      doLogout={this._doLogout}
                    />
                  </Route>

                  <Route path="/settings/subscriptions">
                    <SubscriptionsComponent
                      user={this.props.user}
                      doLogout={this._doLogout}
                    />
                  </Route>

                  <Route exact path="/settings/groups/:id">
                    <ViewGroupComponent
                      user={this.props.user}
                      doLogout={this._doLogout}
                    />
                  </Route>

                  <Route exact path="/settings/groups">
                    <GroupsComponent
                      user={this.props.user}
                      doLogout={this._doLogout}
                    />
                  </Route>

                  <Route path="/settings">
                    <SettingsComponent
                      user={this.props.user}
                      doLogout={this._doLogout}
                    />
                  </Route>

                  {this.renderPremiumRoutes()}
                </Switch>
              )}

              <Route path="/">
                <LaunchComponent isMobile={this.isMobile} />
              </Route>
            </Switch>
          </div>
        </div>
      </Router>
    );
  }
}

function mapStateToProps(state) {
  const { user, navigate, expense, logoutMsg, accounts } = state.app;
  return {
    user,
    navigate,
    editExpenseId: expense?.editExpenseId,
    logoutMsg,
    accounts,
  };
}

App.propTypes = {
  user: PropTypes.object,
  navigate: PropTypes.any,
  editExpenseId: PropTypes.string,
  logoutMsg: PropTypes.string,
  accounts: PropTypes.object,
};

export default connect(mapStateToProps)(App);
